@font-face {
  font-family: "ReemKufi";
  src: url("assets/fonts/ReemKufi-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BebasNeue";
  src: url("assets/fonts/BebasNeue-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ReadexPro";
  src: url("assets/fonts/ReadexPro-VariableFont_HEXP,wght.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ReadexPro";
  src: url("assets/fonts/ReadexPro-VariableFont_HEXP,wght.ttf")
    format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "ReadexPro";
  src: url("assets/fonts/ReadexPro-VariableFont_HEXP,wght.ttf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "ReadexPro";
  src: url("assets/fonts/ReadexPro-VariableFont_HEXP,wght.ttf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

.box-shadow {
  box-shadow: 0px 27px 80px rgba(0, 0, 0, 0.07),
    0px 8.13971px 24.1177px rgba(0, 0, 0, 0.0456112),
    0px 3.38082px 10.0172px rgba(0, 0, 0, 0.035),
    0px 1.22278px 3.62304px rgba(0, 0, 0, 0.0243888);
}

.contact-box-shadow {
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
}

.text-shadow {
  text-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
}

.brand-card {
  background: #ffffff;
  box-shadow: 0px 38px 80px rgba(0, 0, 0, 0.07),
    0px 4.75819px 10.0172px rgba(0, 0, 0, 0.035);
  border-radius: 20px;
}

.gallery {
  -webkit-column-count: 6;
  -moz-column-count: 6;
  column-count: 6;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
  padding: 0 11.41px;
}

.gallery .pics {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  margin-bottom: 11.41px;
}

.gallery .pics:hover {
  filter: opacity(0.8);
}

.card-project {
  position: relative;
  width: 100%;
  /*height: 95.36px;*/

  background: #ffffff;
  box-shadow: 0px 31.7866px 66.9191px rgba(0, 0, 0, 0.07),
    0px 3.98017px 8.37931px rgba(0, 0, 0, 0.035);
  border-radius: 16.7298px;
}

.card-project .number {
  margin: 0;
  width: 42.66px;
  height: 51.86px;

  background: #009a4f;
  box-shadow: 0px 31.7866px 66.9191px rgba(0, 0, 0, 0.07),
    0px 3.98017px 8.37931px rgba(0, 0, 0, 0.035);
  border-radius: 16.7298px 0px;
}

.faq-background {
  background: linear-gradient(rgba(0, 154, 79, 0.8), rgba(0, 154, 79, 0.8)),
    url("assets/png/bg-farm-faq2.png") no-repeat center center / cover;
}

.footer-bg {
  /* Set the background image with a linear gradient overlay */
  background-image: linear-gradient(
      90deg,
      #024731 37.25%,
      rgba(2, 71, 49, 0) 135.14%
    ),
    url(assets/png/footer-bg.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.footer-text-shadow {
  text-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
}

@media (max-width: 1440px) {
  .gallery {
    -webkit-column-count: 6;
    -moz-column-count: 6;
    column-count: 6;
  }
}

@media (max-width: 991px) {
  .gallery {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
}

@media (max-width: 480px) {
  .gallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
  }
}
