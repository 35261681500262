@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .contact-input {
    @apply px-4 lg:px-8 lg:text-xl h-[2.9rem] lg:h-[4.94rem] rounded-full w-full font-readex bg-[#E9E9E9] text-[#555555] focus:outline-none font-readex;
  }

  .contact-textarea {
    @apply p-8 text-xl col-span-2 rounded-[1.56rem] bg-[#E9E9E9] text-[#555555] focus:outline-none font-readex;
  }

  .contact-btn {
    @apply  h-[2.9rem] lg:h-[4.94rem] bg-lemonGreen rounded-full w-full text-white  font-readex;
  }
}
